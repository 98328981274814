import React, {Component} from 'react';
import FilterForm from './filter_form/FilterForm';


import s from './SideFilter.module.css';

class SideFilter extends Component {

    state = {
        filterOpen: false,
    }

    openFilter = () => {
        const currentState = this.state.filterOpen;

        this.setState({
            filterOpen: !currentState,
        })
    }

    render() {

        const {filterOpen} = this.state;

        return (
            
            <div className={`${s.side_filter} ${filterOpen ? `${s.open}` : '' }`} >
                <button onClick={this.openFilter} className={s.open_filter}></button>
                <FilterForm {...this.props} filterOpen={filterOpen} />
            </div>
        );
    } 
  
}

export default SideFilter;