import React, {Component} from 'react';

import s from './SortInput.module.css';
import arrow from '../../../../img/sort-arrow.svg';

const SORT_OPTIONS = [
    {
        id: 'sort-square',
        sortText: 'Общая площадь',
        sortType: 'total_area',
    },
    {
        id: 'sort-mprice',
        sortText: 'Цена за м2',
        sortType: 'price_for_meter',
    },
    {
        id: 'sort-date',
        sortText: 'Дата создания',
        sortType: 'created_at',
    },
    {
        id: 'sort-price',
        sortText: 'Цена за объект',
        sortType: 'price',
    },
    {
        id: 'sort-flooring',
        sortText: 'Этажность',
        sortType: 'max_floor',
    },
    {
        id: 'sort-floor',
        sortText: 'Этаж',
        sortType: 'floor',
    },
    {
        id: 'sort-room',
        sortText: 'Количество комнат',
        sortType: 'rooms_count',
    },
]

class SortInput extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showSort: false,
        }

        this.showSort = this.showSort.bind(this);
        this.hideSort = this.hideSort.bind(this);
    }

    showSort(e) {
        e.preventDefault();

        this.setState({ showSort: true }, () => {
          document.addEventListener('click', this.hideSort);
        });
    }

    hideSort(e) {
        e.preventDefault();

        this.setState({ showSort: false }, () => {
          document.removeEventListener('click', this.hideSort);
        });
    }

    render() {

        const {showSort} = this.state;
        const {handleSort,sort,sortBy} = this.props;

        return(

            <div className={s.object_sort}>
                <div className={s.filter_sort_by} onClick={this.showSort}>
                    <span className={s.sort_by_value}>
                        { sortBy !== '' && sortBy === 'total_area' ? 'Общая площадь' :
                          sortBy !== '' && sortBy === 'price_for_meter' ? 'Цена за м2' :
                          sortBy !== '' && sortBy === 'created_at' ? 'Дата создания' :
                          sortBy !== '' && sortBy === 'price' ? 'Цена за объект' :
                          sortBy !== '' && sortBy === 'max_floor' ? 'Этажность' :
                          sortBy !== '' && sortBy === 'floor' ? 'Этаж' :
                          sortBy !== '' && sortBy === 'rooms_count' ? 'Количество комнат' 
                          : 'Не выбрано' }
                    </span>
                    {sort === 'asc' ? 
                        <span className={s.sort_arrow}>
                            <img src={arrow} alt="Arrow"/>
                        </span> 
                        : sort === 'desc' ?
                        <span className={`${s.sort_arrow} ${s.sort_arrow_down}`}>
                            <img src={arrow} alt="Arrow"/>
                        </span>
                        : sort === '' ? 
                        <span></span>
                        : <> </>
                    }
                    
                </div>
                {showSort ? (
                    <ul className={s.filter_sort_options}>
                    {SORT_OPTIONS.map(({id, sortType, sortText}) => (
                        <li key={id} className={s.filter_sort_option}>
                            <p data-sort-name={sortType} className={s.sort_option_value}>
                                {sortText}
                            </p>
                            <button data-sort-type="asc" className={s.up} onClick={handleSort}></button>
                            <button data-sort-type="desc" className={s.down} onClick={handleSort}></button>
                        </li>
                    ))}
                </ul>
                ) : (null) 
                }
            </div>

        );
    }
}

export default SortInput;