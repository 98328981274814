import React, {Component} from 'react';

import s from './ObjectType.module.css';

class ObjectType extends Component {

    handleClick = (event) => {
        if (window.location.href.includes('/id')) this.props.getObjType(event)
    }

    render() {

        const {getObjType, objectType} = this.props;


        return (
            <div className={s.filter_obj}>
                <label className={s.obj_item}>
                    <input onClick={e => this.handleClick(e)} onChange={getObjType} id="flat" checked={objectType === 'flat'} name="object_type_radio" type="radio"/>
                    <span>Квартиры</span>
                </label>
                <label className={s.obj_item}>
                    <input onClick={this.handleClick} onChange={getObjType} id="private-house" checked={objectType === 'private-house'} name="object_type_radio" type="radio"/>
                    <span>Частный <br/> дом</span>
                </label>
                <label className={s.obj_item}>
                    <input onClick={this.handleClick} onChange={getObjType} id="land" checked={objectType === 'land'} name="object_type_radio" type="radio"/>
                    <span>Земельный <br/> участок</span>
                </label>
                <label className={s.obj_item}>
                    <input onClick={this.handleClick} onChange={getObjType} id="commerce" checked={objectType === 'commerce'} name="object_type_radio" type="radio"/>
                    <span>Коммерция</span>
                </label>
            </div>
        );
    }

}

export default ObjectType;
