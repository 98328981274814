import React, {Component} from 'react';
import Header from './header/Header';
import Content from './main/Content';
import Footer from "./footer/Footer";


class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      objectID: '',
      sortBy: '',
      sort: '',
    }

    this.resetSort = this.resetSort.bind(this);
  }

  // CUSTOM METHODS

  handleSort = (event) => {

    let sortType = event.target.dataset.sortType;
    let sortName = event.target.parentNode.querySelectorAll('p')[0].dataset.sortName;
    this.props.handleSort(event)
    this.setState({
      sortBy: sortName,
      sort: sortType
    })
  }

  handleObjID = ({target: {value}}) => {
    this.setState({
      objectID: value,
    })
    this.props.handleObjectId(value)
  }

  resetID = () => {
    this.setState({
      objectID: '',
    })
  }

  resetSort = () => {
    this.setState({
      sortBy: '',
      sort: '',
    })
  }

  ////////////////

  render() {

    const { sortBy, sort} = this.state;
    const {getObjectInfo, handleObjectType, objectType, objects, handleObjectId, response, handleParam, handleFilter, handleSubmitFilter, handleResetFilterForm, objectID} = this.props;

    return (
      <div className="custom_container">
          <Header handleObjectType={handleObjectType} getObjectInfo={getObjectInfo} objectType={objectType} />
          <Content objectType={objectType} handleResetFilterForm={handleResetFilterForm} handleSubmitFilter={handleSubmitFilter} handleFilter={handleFilter} handleParam={handleParam} response={response} handleSort={this.handleSort} objects={objects} handleObjectId={handleObjectId} resetID={this.resetID} resetSort={this.resetSort} getObjectInfo={getObjectInfo} objectID={objectID} sortBy={sortBy} sort={sort} />
          <Footer handleObjectType={handleObjectType}/>
      </div>
    );
  }

}

export default HomePage;
