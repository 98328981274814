import React, {Component} from 'react';

import s from '../FilterForm.module.css';

class PrivateForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            objects: [],
        }

    }

    render() {

        const {price_from, price_to, total_area_from, total_area_to, area_square_from, area_square_to, onSubmit, onChange, handleReset} = this.props;

        return (

            <form className={s.filter_form} onSubmit={onSubmit} action="">
                
                <div className={s.filter_row}>

                    <div className={s.filter_row_head}>
                        <div className="col-4">
                        <span className={s.description}>Цена</span>
                        </div>
                        <div className="col-4">
                            <label>
                                <input 
                                onChange={onChange} 
                                checked={this.props.price_for === '1'} 
                                value="1" 
                                id="price_for_object" 
                                name="price_for" 
                                type="radio"/>
                                <span>За объект</span>
                            </label>
                        </div>
                        <div className="col-4">
                            <label>
                                <input 
                                onChange={onChange} 
                                checked={this.props.price_for === '2'} 
                                value="2" 
                                id="price_for_meter" 
                                name="price_for" 
                                type="radio"/>
                                <span>за м<sup>2</sup></span>
                            </label>
                        </div>
                    </div>

                    <div className={s.filter_row_body}>
                        <div className="col-4">
                            <input 
                            onChange={onChange} 
                            value={price_from} 
                            id="price_from" 
                            name="price_from" 
                            type="text" 
                            placeholder="От"/>
                        </div>
                        <div className="col-4">
                            <input 
                            onChange={onChange} 
                            value={price_to} 
                            id="price_to" 
                            name="price_to" 
                            type="text" 
                            placeholder="До"/>
                        </div>
                        <div className="col-4">
                            <select disabled name="value" id="value">
                                <option value="USD">USD</option>
                            </select>
                        </div>
                    </div>

                </div>

                <div className={`${s.filter_row} ${s.filter_row_flex}`}>

                    <div className="col-6">
                        <span className={s.description}>Общая S дома, м<sup>2</sup></span>
                        <div className={s.flexbox}>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={total_area_from} 
                                id="total_area_from" 
                                name="total_area_from" 
                                type="text" 
                                placeholder="От" />
                            </div>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={total_area_to} 
                                id="total_area_to" 
                                name="total_area_to" 
                                type="text" 
                                placeholder="До" />
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <span className={s.description}>S участка, сот.</span>
                        <div className={s.flexbox}>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={area_square_from} 
                                id="area_square_from"
                                name="area_square_from"
                                type="text" 
                                placeholder="От" />
                            </div>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={area_square_to} 
                                id="area_square_to" 
                                name="area_square_to" 
                                type="text" 
                                placeholder="До" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className={s.filter_buttons_row}>
                    <button id="submit" className={s.submit_filter}>
                        Применить
                    </button>
                    <button onClick={handleReset} className={s.reset_filter}>
                        Отмена
                    </button>
                </div>

            </form>
            
        );
    } 
  
}

export default PrivateForm;