import React,{Component} from 'react';
import s from './ObjectPage.module.css';

class ObjectInfoItem extends Component {

    render() {

        const {title,value,noInfo,multi} = this.props;


        return(
            <div className={`${s.info_item} ${multi ? s.multi : ''}`}>
                <span className={s.item_title}>
                    {title}:
                </span>
                {
                    !noInfo ? 
                    <span className={s.item_value}>
                    {value}
                    </span> :
                    <span className={`${s.item_value} ${s.no_info}`}>
                    Нет информации
                    </span>
                }
            </div>
        )
        
    }
}



export default ObjectInfoItem;
