import React, {Component} from 'react';

import s from '../FilterForm.module.css';

class FlatForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            objects: [],
        }
    }

    render() {

        const {floor_from, floor_to, max_floor_from, max_floor_to, price_from, price_to, total_area_from, total_area_to, not_first_floor, not_last_floor, cnt_room_1, cnt_room_2, cnt_room_3, cnt_room_4, rooms_count, onChange, onSubmit, handleReset, handleFloors, handleRooms} = this.props;

        return (

            <form className={s.filter_form} onSubmit={onSubmit} action="">
             
                <div className={`${s.filter_row} ${s.filter_row_flex}`}>

                    <div className="col-4">
                        <span className={s.description}>Этаж</span>
                        <div className={s.flexbox}>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={floor_from} 
                                id="floor_from" 
                                name="floor_from" 
                                type="text" 
                                placeholder="От" />
                            </div>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={floor_to} 
                                id="floor_to" 
                                name="floor_to" 
                                type="text" 
                                placeholder="До" />
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <span className={s.description}>Этажность</span>
                        <div className={s.flexbox}>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={max_floor_from} 
                                id="max_floor_from" 
                                name="max_floor_from" 
                                type="text" 
                                placeholder="От" />
                            </div>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={max_floor_to} 
                                id="max_floor_to" 
                                name="max_floor_to" 
                                type="text" 
                                placeholder="До" />
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <label className={s.floor_label}>
                            <input onChange={handleFloors}
                            checked={not_first_floor === '1'} 
                            id="not_first_floor" 
                            name="not_first_floor" 
                            type="checkbox"/>
                            <span>Не первый</span>
                        </label>
                        <label className={s.floor_label}>
                            <input onChange={handleFloors}
                            checked={not_last_floor === '1'}
                            id="not_last_floor" 
                            name="not_last_floor" 
                            type="checkbox"/>
                            <span>Не последний</span>
                        </label>
                    </div>

                </div>

                <div className={s.filter_row}>

                    <div className={s.filter_row_head}>
                        <div className="col-4">
                        <span className={s.description}>Цена</span>
                        </div>
                        <div className="col-4">
                            <label>
                                <input 
                                onChange={onChange} 
                                checked={this.props.price_for === '1'} 
                                value="1" 
                                id="price_for_object" 
                                name="price_for" 
                                type="radio"/>
                                <span>За объект</span>
                            </label>
                        </div>
                        <div className="col-4">
                            <label>
                                <input 
                                onChange={onChange} 
                                checked={this.props.price_for === '2'} 
                                value="2" 
                                id="price_for_meter" 
                                name="price_for" 
                                type="radio"/>
                                <span>за м<sup>2</sup></span>
                            </label>
                        </div>
                    </div>

                    <div className={s.filter_row_body}>
                        <div className="col-4">
                            <input 
                            onChange={onChange} 
                            value={price_from} 
                            id="price_from" 
                            name="price_from" 
                            type="text" 
                            placeholder="От"/>
                        </div>
                        <div className="col-4">
                            <input 
                            onChange={onChange} 
                            value={price_to} 
                            id="price_to" 
                            name="price_to" 
                            type="text" 
                            placeholder="До"/>
                        </div>
                        <div className="col-4">
                            <select disabled name="value" id="value">
                                <option value="USD">USD</option>
                            </select>
                        </div>
                    </div>

                </div>

                <div className={`${s.filter_row} ${s.filter_row_flex}`}>

                    <div className="col-4">
                        <span className={s.description}>Площадь, м<sup>2</sup></span>
                        <div className={s.flexbox}>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={total_area_from} 
                                id="total_area_from" 
                                name="total_area_from" 
                                type="text" 
                                placeholder="От" />
                            </div>
                            <div className={s.item}>
                                <input 
                                onChange={onChange} 
                                value={total_area_to} 
                                id="total_area_to" 
                                name="total_area_to" 
                                type="text" 
                                placeholder="До" />
                            </div>
                        </div>
                    </div>

                    <div className="col-8">
                        <span className={s.description}>Комнат</span>
                        <div className={s.flexbox}>
                            <label className={s.room_count_label}>
                                <input
                                onChange={handleRooms}
                                value="1"
                                checked={cnt_room_1 === '1'}
                                id="cnt_room_1" 
                                name="cnt_room_1" 
                                type="checkbox"/>
                                <span>1</span>
                            </label>
                            <label className={s.room_count_label}>
                                <input
                                onChange={handleRooms}
                                value="2"
                                checked={cnt_room_2 === '2'}
                                id="cnt_room_2" 
                                name="cnt_room_2" 
                                type="checkbox"/>
                                <span>2</span>
                            </label>
                            <label className={s.room_count_label}>
                                <input
                                onChange={handleRooms}
                                value="3"
                                checked={cnt_room_3 === '3'}
                                id="cnt_room_3" 
                                name="cnt_room_3" 
                                type="checkbox"/>
                                <span>3</span>
                            </label>
                            <label className={s.room_count_label}>
                                <input
                                onChange={handleRooms}
                                value="4"
                                checked={cnt_room_4 === '4'}
                                id="cnt_room_4" 
                                name="cnt_room_4" 
                                type="checkbox"/>
                                <span>4+</span>
                            </label>
                            <div className={s.room_input}>
                                <input
                                onChange={onChange}
                                value={rooms_count} 
                                id="rooms_count" 
                                name="rooms_count" 
                                type="text"/>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={s.filter_buttons_row}>
                    <button id="submit" className={s.submit_filter}>
                        Применить
                    </button>
                    <button onClick={handleReset} className={s.reset_filter}>
                        Отмена
                    </button>
                </div>

            </form>

        );
    } 
  
}

export default FlatForm;