import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';

import s from './Address.module.css';

class MainAddress extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            country: 1,
            regions: [],
            areas: [],
            cities: [],
            selectedRegion: '',
            selectedArea: '',
            selectedCity: '',
            selectedRegionName: '',
            selectedAreaName: '',
            selectedCityName: '',
            all_region: 0,
            all_area: 0,
            all_city: 0,
        }

        this.baseState = this.state;

    }

    // MODAL METHODS

    handleShow = () => {
        this.setState({
            show: true,
        })
    }

    handleClose = () => {
        this.setState({
            show: false,
            areas: [],
            cities: [],
            selectedRegion: '',
            selectedArea: '',
            selectedCity: '',
            selectedRegionName: '',
            selectedAreaName: '',
            selectedCityName: '',
            all_region: 0,
            all_area: 0,
            all_city: 0,
        });
    };

    handleSubmit = () => {

        const {getMainAddress} = this.props;
        const {selectedRegion, selectedArea, selectedCity, selectedRegionName, selectedAreaName, selectedCityName, all_region, all_area, all_city} = this.state;

        console.log(selectedRegionName);

        this.setState({
            show: false,
        })

        getMainAddress(selectedRegion,selectedArea,selectedCity,selectedRegionName,selectedAreaName,selectedCityName,all_region, all_area, all_city);

        this.handleClose();
    }

    //////

    // REGIONS METHODS

    componentDidMount() {

        const {country} = this.state;

        fetch(`${process.env.REACT_APP_APP_URL}/export-api/address/region/${country}`, { 
            headers: new Headers({
              'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
            }), 
        }).then(res => res.json())
        .then(regions => this.setRegions(regions))
        .catch(error => console.error('Error:', error));

    }

    setRegions = (regions) => {
        this.setState({
            regions: regions.result
        })
    }

    handleRegionClick = (e) => {

        const {value,name} = e.target;

        this.setState({
            selectedRegion: value,
            selectedRegionName: name
        })        

        let regionButtons = e.target.parentNode.querySelectorAll('button');

        regionButtons.forEach.call(regionButtons, function(el) {
            el.classList.remove('active');
        });

        e.target.classList.add('active');

        this.getAreas(value);

    }

    handleAllRegions = (e) =>{

        const{checked} = e.target;

        let parentItem = e.target.closest('.item');
        let regionBlock = parentItem.getElementsByClassName('region_id');
        let parentSibling = parentItem.nextSibling;
        let lastItem = parentSibling.nextSibling;

        if(checked) {
            this.setState({
                all_region: 1
            })

            regionBlock[0].classList.add('disabled_content');
            parentSibling.classList.add('disabled_content');
            lastItem.classList.add('disabled_content');

        } else {
            this.setState({
                all_region: 0
            })

            regionBlock[0].classList.remove('disabled_content');
            parentSibling.classList.remove('disabled_content');
            lastItem.classList.remove('disabled_content');
        }
        
    }

    /////////////////

    // AREAS METHODS

    getAreas = (selectedRegion) => {
        fetch(`${process.env.REACT_APP_APP_URL}/export-api/address/area/${selectedRegion}`, { 
            headers: new Headers({
              'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
            }), 
        }).then(res => res.json())
        .then(areas => this.setAreas(areas))
        .catch(error => console.error('Error:', error));
    }

    setAreas = (areas) => {
        this.setState({
            areas: areas.result
        })
    }

    handleAreaClick = (e) => {

        const {value, name} = e.target;

        this.setState({
            selectedArea: value,
            selectedAreaName: name
        })        

        let areaButtons = e.target.parentNode.querySelectorAll('button');

        areaButtons.forEach.call(areaButtons, function(el) {
            el.classList.remove('active');
        });

        e.target.classList.add('active');

        this.getCities(value);

    }

    handleAllAreas = (e) =>{

        const{checked} = e.target;

        let parentItem = e.target.closest('.item');
        let areaBlock = parentItem.getElementsByClassName('area_id');
        let parentSibling = parentItem.nextSibling;

        if(checked) {
            this.setState({
                all_area: 1
            })

            areaBlock[0].classList.add('disabled_content');
            parentSibling.classList.add('disabled_content');

        } else {
            this.setState({
                all_area: 0
            })

            areaBlock[0].classList.remove('disabled_content');
            parentSibling.classList.remove('disabled_content');
        }
        
    }

    ///////////////

    // CITIES METHODS

    getCities = (selectedCity) => {
        fetch(`${process.env.REACT_APP_APP_URL}/export-api/address/city/${selectedCity}`, { 
            headers: new Headers({
              'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
            }), 
        }).then(res => res.json())
        .then(cities => this.setCities(cities))
        .catch(error => console.error('Error:', error));
    }

    setCities = (cities) => {
        this.setState({
            cities: cities.result
        })
    }

    handleCityClick = (e) => {

        const {value,name} = e.target;

        this.setState({
            selectedCity: value,
            selectedCityName: name
        })        

        let areaButtons = e.target.parentNode.querySelectorAll('button');

        areaButtons.forEach.call(areaButtons, function(el) {
            el.classList.remove('active');
        });

        e.target.classList.add('active');


    }

    handleAllCities = (e) =>{

        const{checked} = e.target;

        let parentItem = e.target.closest('.item');
        let cityBlock = parentItem.getElementsByClassName('city_id');

        if(checked) {
            this.setState({
                all_city: 1
            })

            cityBlock[0].classList.add('disabled_content');

        } else {
            this.setState({
                all_city: 0
            })

            cityBlock[0].classList.remove('disabled_content');
        }
        
    }

    ////////////////

    componentDidUpdate(prevProps) {

        const{selectedRegionName} = this.props;

        if (selectedRegionName === '' && prevProps.selectedRegionName !== '' ) {

            this.handleClose();
        }

    }

    render() {

        const {show, regions, areas, cities, all_region, all_area, all_city} = this.state;
        const {selectedRegionName, selectedAreaName, selectedCityName} = this.props

        return (

            <>
            
                <label className={`${s.main_location} ${s.filter_row}`}>
                    <span className={s.description}>Область → Регион → Насленный пункт</span>
                    <input onClick={this.handleShow}
                    value={ `${selectedRegionName} ${selectedRegionName.length !== 0 ? ',' : '' } ${selectedAreaName} ${selectedAreaName.length !== 0 ? ',' : ''} ${selectedCityName} ` }  
                    className={s.main_location_input}  
                    readOnly={true} 
                    id="main_location" 
                    type="text"/>
                </label>

                <Modal 
                show={show} 
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                dialogClassName="addressModal"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Местоположение
                        </h5>
                    </div>
                <div className="modal-body">
                        <div className="listbox">
                            <div className="item">
                                <h6>
                                    <span>Область</span>
                                    <label>
                                        <input onChange={this.handleAllRegions} id="all_region" checked={all_region === 1} type="checkbox"/>
                                        Выбрать все
                                    </label>
                                </h6>
                                <div className="region_id">
                                    {regions.map(({id, name}) => 
                                        <button onClick={this.handleRegionClick} key={`region_${id}`} value={id} name={name} className="address_button">
                                            {name}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="item">
                                <h6>
                                    <span>Район</span>
                                    <label>
                                        <input onChange={this.handleAllAreas} id="all_area" checked={all_area === 1} type="checkbox"/>
                                        Выбрать все
                                    </label>
                                </h6>
                                <div className="area_id">
                                    {areas.map(({id, name}) => 
                                        <button onClick={this.handleAreaClick} key={`area_${id}`} value={id} name={name} className="address_button">
                                            {name}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="item">
                                <h6>
                                    <span>Населенный пункт (город)</span>
                                    <label>
                                        <input onChange={this.handleAllCities} id="all_city" checked={all_city === 1} type="checkbox"/>
                                        Выбрать все
                                    </label>
                                </h6>
                                <div className="city_id">
                                    {cities.map(({id, name}) => 
                                        <button onClick={this.handleCityClick} key={`city_${id}`} value={id} name={name} className="address_button">
                                            {name}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                </div>
                <div className="modal-footer">
                    <button 
                    onClick={this.handleClose} 
                    className="close_modal">
                        Отмена
                    </button>
                    <button 
                    onClick={this.handleSubmit} 
                    className="submit_modal">
                        Применить
                    </button>
                </div>

                </Modal>

            </>
            
        );
    } 
  
}

export default MainAddress;