import React,{Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import HomePage from './components/HomePage';
import ObjectPage from './components/ObjectPage';

const params = {
  objectType: '',
  response: '',
  floor_from: '',
  floor_to: '',
  max_floor_from: '',
  max_floor_to: '',
  not_first_floor: '0',
  not_last_floor: '0',
  price_for: '1',
  price_from: '',
  price_to: '',
  total_area_from: '',
  total_area_to: '',
  cnt_room_1: '',
  cnt_room_2: '',
  cnt_room_3: '',
  cnt_room_4: '',
  rooms_count: '',
  land_plot_area_from: '',
  land_plot_area_to: '',
  square_value: '',
  release_date_from: '',
  release_date_to: '',
  region_id: '',
  area_id: '',
  city_id: '',
  selectedRegionName: '',
  selectedAreaName: '',
  selectedCityName: '',
  district_id: [],
  microarea_id: [],
  landmark_id: [],
  not_obj: 'not_obj',
  all_region: 0,
  all_area: 0,
  all_city: 0,
  id: '',
  sort_order: '',
  sort_by: '',
  type: '',
  page: 1,
}

class App extends Component {

  state = {
    objectType: '',
    objectID: '',
    objects: [],
    response: {},
    params: params
  }

  getObjectInfo = (e) => {
    const{objectType, id} = e.target.dataset;

   this.setState({
     objectType: objectType,
     objectID: id,
   })

  }

  handleObjectType = (type) => {
    localStorage.setItem('objectType', type);
    this.setState( state =>({
      objectType: type,
      objectID: '',
      params : {
        response: '',
        floor_from: '',
        floor_to: '',
        max_floor_from: '',
        max_floor_to: '',
        not_first_floor: '0',
        not_last_floor: '0',
        price_for: '1',
        price_from: '',
        price_to: '',
        total_area_from: '',
        total_area_to: '',
        cnt_room_1: '',
        cnt_room_2: '',
        cnt_room_3: '',
        cnt_room_4: '',
        rooms_count: '',
        land_plot_area_from: '',
        land_plot_area_to: '',
        square_value: '',
        release_date_from: '',
        release_date_to: '',
        region_id: '',
        area_id: '',
        city_id: '',
        selectedRegionName: '',
        selectedAreaName: '',
        selectedCityName: '',
        district_id: [],
        microarea_id: [],
        landmark_id: [],
        not_obj: 'not_obj',
        all_region: 0,
        all_area: 0,
        all_city: 0,
        id: '',
        sort_order: '',
        sort_by: '',
        type: '',
        page: 1,
        objectType: type
      }
    }), this.getObjects)
  }

  handleObjectId = (id) => {
    this.setState(state => ({
      objectID: id,
      params: {
        ...state.params,
        id: id
      }
    }), this.getObjects)
  }

  handleParam = (key, value) => {
    this.setState(state => ({
      params: {
        ...state.params,
        [key]: value
      }
    }), this.getObjects)
  }

  handleFilter = (filters) => {
   Object.entries(filters)
        .filter(([key, value]) => `${key}`[value] !== 'undefined' )
        .map((item) => {
          this.setState(state => ({
          params: {
            ...state.params,
            [item[0]]: item[1]
          }
        }))
    })
  }

  handleSort = (event) => {
    let sortType = event.target.dataset.sortType;
    let sortName = event.target.parentNode.querySelectorAll('p')[0].dataset.sortName;
    this.setState(state => ({
      params: {
        ...state.params,
        sort_order: sortType,
        sort_by: sortName,
      }
    }), this.getObjects)

  }

  handleSubmitFilter = () => {
    this.getObjects()
  }

  handleResetFilterForm = () => {
    this.setState({
      params: params,
      objectID: ''
    }, () => {
      this.setState(state => ({
        params: {
          ...state.params,
          objectType: state.objectType
        }
      }), () => {
        this.getObjects()
      })
    })
  }

  getObjects = () => {
    const url = new URL(`${process.env.REACT_APP_APP_URL}/api/objects`);
    let params = this.state.params;
    url.search = new URLSearchParams(params).toString();

    fetch(url, {
      headers: new Headers({
        'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
      }),
    }).then(res => res.json())
        .then(response => this.setState({
          objects: response.data,
          response: response
        }))
        .catch(error => console.error('Error:', error));
  }

  render() {

    const {objectType, objectID, objects, response} = this.state;

    return(
      <BrowserRouter>
        <Switch>
          <Route
          exact
          path="/"
          render={(props) => <HomePage {...props} objectID={objectID} handleResetFilterForm={this.handleResetFilterForm} handleSubmitFilter={this.handleSubmitFilter} handleFilter={this.handleFilter} handleSort={this.handleSort} handleParam={this.handleParam} response={response} handleObjectId={this.handleObjectId} objects={objects} objectType={objectType} handleObjectType={this.handleObjectType}  getObjectInfo={this.getObjectInfo} />} />
          <Route
          path="/:type/:id"
          render={(props) => <ObjectPage {...props} getObjectInfo={this.getObjectInfo} handleObjectType={this.handleObjectType} objectType={objectType} objectID={objectID} />} />
        </Switch>
      </BrowserRouter>
    )
  }

}


export default App;
