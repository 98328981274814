import React, {Component} from 'react';
import Logo from './logo/Logo';
import s from './Header.module.css';
import ObjectType from "../main/side_filter/filter_form/object_type/ObjectType";

class Header extends Component {

    state = {
        logoSrc: '',
    }

    componentDidMount() {
        const localObj = localStorage.getItem('objectType');
        if(localObj === null) {
            this.props.handleObjectType('flat')
        } else {
            this.props.handleObjectType(localObj)
        }
       this.getLogo()
    }

    getObjType = ({target: {id}}) => {
        this.props.handleObjectType(id)
        if (window.location.href.includes('/id')) window.location.href = '/'
    }

    getLogo = () => {
        const url = new URL(`${process.env.REACT_APP_APP_URL}/api/logo`);

        fetch(url, {
            headers: new Headers({
                'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`,
            }),
        }).then(res => res.json())
            .then(response => this.setState({
                logoSrc: response.data.link,
            }))
            .catch(error => this.setState({
                logoSrc: 'https://realdev.insidergroup.pro/storage/images/private_house/1571471277pCD9z7G9Ma5J4eBwBPeA3BE1iobkaU.png'
            }));
    }

    render() {
        const {logoSrc} = this.state;
        const {objectType} = this.props;

        return (
            <header className={s.header}>
                <Logo src={logoSrc}/>
                <ObjectType getObjType={this.getObjType} objectType={objectType}/>
            </header>
        );
    }

}

export default Header;
