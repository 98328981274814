import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';

import s from './Address.module.css';

Object.defineProperty(Array.prototype, 'chunk_inefficient', {
    value: function(chunkSize) {
        var array = this;
        return [].concat.apply([],
            array.map(function(elem, i) {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            })
        );
    }
});

class SubAddress extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            districts: [],
            selectedDistricts: [],
            microareas: [],
            selectedMicroareas: [],
            landmarks: [],
            selectedLandmarks: [],
            not_obj: 'not_obj',
        }

        this.baseState = this.state;
    }

    handleShow = () => {
        
        const{city_id} = this.props;

        document.getElementById('preview').innerHTML = '';

        fetch(`${process.env.REACT_APP_APP_URL}/export-api/address/district/${city_id}`, { 
            headers: new Headers({
              'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
            }), 
        }).then(res => res.json())
        .then(districts => this.setDistricts(districts))
        .catch(error => console.error('Error:', error));

        fetch(`${process.env.REACT_APP_APP_URL}/export-api/address/microarea/${city_id}`, { 
            headers: new Headers({
              'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
            }), 
        }).then(res => res.json())
        .then(microareas => this.setMicroareas(microareas))
        .catch(error => console.error('Error:', error));

        fetch(`${process.env.REACT_APP_APP_URL}/export-api/address/landmark/${city_id}`, { 
            headers: new Headers({
              'Authorization': `Bearer ${process.env.REACT_APP_TOKEN}`, 
            }), 
        }).then(res => res.json())
        .then(landmarks => this.setLandmarks(landmarks))
        .catch(error => console.error('Error:', error));

        this.setState({
            show: true
        })
    }

    componentDidUpdate(prevProps)  {

        const{city} = this.props;

        if(city !== prevProps.city) {
            this.handleClose();
        }

    }

    handleClose = () => {
        this.setState({
            show: false,
            districts: [],
            selectedDistricts: [],
            microareas: [],
            selectedMicroareas: [],
            landmarks: [],
            selectedLandmarks: [],
            not_obj: 'not_obj',
        });
    }

    setDistricts = (districts) => {
        this.setState({
            districts: districts.result
        })
    }

    setMicroareas = (microareas) => {
        this.setState({
            microareas: microareas.result
        })
    }

    setLandmarks = (landmarks) => {
        this.setState({
            landmarks: landmarks.result
        })
    }

    handleDistricts = (e) => {

        const {selectedDistricts} = this.state;
        const {id,checked} = e.target;

        if(checked) {
            selectedDistricts.push(id);

            this.setState({
                selectedDistricts: selectedDistricts,
            })

        } else {
            selectedDistricts.forEach(function(currentValue, index) {
                if(currentValue === id) {
                    selectedDistricts.splice(index, 1);
                }
            });

            this.setState({
                selectedDistricts: selectedDistricts,
            })

        }

    }

    showLandmarks = (microarea_id) => {
        let landmarksLabels = document.getElementsByClassName('landmark_label');

        for (let landmarkLabel of  landmarksLabels) {
            let landmark_id = landmarkLabel.dataset.labelId;

            if(landmark_id === microarea_id) {
                landmarkLabel.classList.remove('hidden');
                let landmark_input = landmarkLabel.firstChild;

                if(!landmark_input.checked) {
                    landmark_input.click();
                }
            }

        }
    }

    hideLandmarks = (microarea_id) => {

        let landmarksLabels = document.getElementsByClassName('landmark_label');

        for (let landmarkLabel of  landmarksLabels) {
            let landmark_id = landmarkLabel.dataset.labelId;

            if(landmark_id === microarea_id) {
                landmarkLabel.classList.add('hidden');
                let landmark_input = landmarkLabel.firstChild;

                if(landmark_input.checked) {
                    landmark_input.click();
                }
            }

        }
    }

    handleMicroareas = (e) => {

        const {selectedMicroareas} = this.state;
        const {id,checked} = e.target;

        if(checked) {
            selectedMicroareas.push(id);

            this.showLandmarks(id);

            this.setState({
                selectedMicroareas: selectedMicroareas,
            })

        } else {
            selectedMicroareas.forEach(function(currentValue, index) {
                if(currentValue === id) {
                    selectedMicroareas.splice(index, 1);
                }
            });

            this.hideLandmarks(id);

            this.setState({
                selectedMicroareas: selectedMicroareas,
            })

        }

    }

    handleLandmarks = (e) => {

        const {selectedLandmarks} = this.state;
        const {id,checked} = e.target;

        if(checked) {
            selectedLandmarks.push(id);

            this.setState({
                selectedLandmarks: selectedLandmarks,
            })

        } else {
            selectedLandmarks.forEach(function(currentValue, index) {
                if(currentValue === id) {
                    selectedLandmarks.splice(index, 1);
                }
            });

            this.setState({
                selectedLandmarks: selectedLandmarks,
            })

        }

    }

    handleNotObj = (e) => {

        const {name,value} = e.target;

        if( this.state[name] === value) {
            this.setState({
                [name] : ''
            })
        } else {
            this.setState({
                [name] : value
            })
        }

    }


    handleSubmit = () => {

        const {getSubAddress} = this.props;
        const {selectedDistricts,selectedMicroareas,selectedLandmarks,not_obj} = this.state;

        this.setState({
            show: false
        })

        getSubAddress(selectedDistricts,selectedMicroareas,selectedLandmarks,not_obj);

        function getAllElementsWithAttribute(attribute)
        {
            var matchingElements = [];
            var allElements = document.getElementsByTagName('*');
            for (var i = 0, n = allElements.length; i < n; i++)
            {
                if (allElements[i].getAttribute(attribute) !== null)
                {
                // Element exists with attribute. Add to array.
                matchingElements.push(allElements[i]);
                }
            }
            return matchingElements;
        }

        let $string = '';
        let dist = getAllElementsWithAttribute('data-dist-id');
        let micros = getAllElementsWithAttribute('data-district-id');
        let lands = getAllElementsWithAttribute('data-microarea-id');

        let checkedDist = [];
        let checkedMicro = [];
        let checkedLands = [];

        dist.forEach((item) =>{
            if(item.checked){
                checkedDist.push(item);
            }
        });

        micros.forEach((item) =>{
            if(item.checked){
                checkedMicro.push(item);
            }
        });

        lands.forEach((item) =>{
            if(item.checked){
                checkedLands.push(item);
            }
        });
        
        let chunk = checkedMicro.length / checkedDist.length;
        let resultMicroarea = [];
        let resultMicroareaID = [];
        let resultLandmark = [];

        checkedMicro.forEach((item) => {
            let val = item.getAttribute('name');
            let id = item.getAttribute('id');
            resultMicroarea.push(val);
            resultMicroareaID.push(id);
        });

        resultMicroareaID.forEach((value) => {
            let list = [];
            checkedLands.forEach((item) => {
                let id = item.dataset.microareaId;
                if(id == value) {
                    list.push(item.getAttribute('name'));
                }
            })
            resultLandmark.push(list);
        });

        let micr = resultMicroarea.chunk_inefficient(Math.ceil(chunk));

        let i = 0;
        let j = 0;

        if(checkedDist.length > 0) {
            checkedDist.forEach((item) => {
                let val = item.getAttribute('name');
                $string = $string+"<div style='color: #2D9CDB'><strong>"+val+"</strong></div>";
                if(micr[i] != undefined && micr[i].length > 0) {
                    $string = $string+ "<div>";
                    micr[i].forEach((value) => {
                        if(value !== '') {
                            $string = $string+"<strong>"+value+", </strong>";
                            if( resultLandmark[j].length > 0 ) {
                                let listLand = resultLandmark[j].join(', ');
                                $string = $string + listLand + "<br>";
                            }
                            ++j
                        }
                    })
                    $string = $string+ "<div>";
                }
                i+=1
            })
        } else {
            $string = $string+ "<div>";
            resultMicroarea.forEach((value) => {
                if(value !== '') {
                    $string = $string+"<strong>"+value+", </strong>";
                    if( resultLandmark[j].length > 0 ) {
                        let listLand = resultLandmark[j].join(', ');
                        $string = $string + listLand + "<br>";
                    }
                    ++j
                }
            });
            $string = $string+ "<div>";
        }

        document.getElementById('preview').innerHTML = $string;

        this.handleClose();

    }

    render() {

        const {show, districts, microareas, landmarks, not_obj,selectedMicroareas} = this.state;

        return (

            <>
            
                <div className={`${s.sub_location} ${s.filter_row}`}>
                    <span onClick={this.handleShow} id="sub_location">Админ. район → Микрорайон → Ориентир</span>
                </div>
                <div id="preview" className={s.preview}>

                </div>

                <Modal 
                show={show} 
                onHide={this.handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
                dialogClassName="addressModalSub"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            Местоположение
                        </h5>
                    </div>
                <div className="modal-body">
                        <div className="listbox arr">
                            <div className="item">
                                <h6>Административный район</h6>
                                <i className="arrow fas fa-long-arrow-alt-right"></i>
                                <div className="adminarea_id">
                                    {districts.map(({id, name}) => 
                                        <label key={`adminarea_${id}`}>
                                            <input onChange={this.handleDistricts} id={id} data-dist-id={id} name={name} type="checkbox"/>
                                            <span >{name}</span>
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className="item">
                                <h6>Микрорайон</h6>
                                <i className="arrow fas fa-long-arrow-alt-right"></i>
                                <div className="microarea_id">
                                    {microareas.map(({id, name, district_id}) => 
                                        <label key={`microarea_${id}`}>
                                            <input onChange={this.handleMicroareas} data-district-id={district_id} id={id} name={name} type="checkbox"/>
                                            <span >{name}</span>
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className="item">
                                <h6>Ориентир</h6>
                                <div className="landmark_id column-2">
                                    {landmarks.map(({id, name, microarea_id}) => 
                                        <label className="hidden landmark_label" data-label-id={microarea_id} key={`landmark_${id}`}>
                                            <input onChange={this.handleLandmarks} data-microarea-id={microarea_id} id={id} name={name} type="checkbox"/>
                                            <span >{name}</span>
                                        </label>
                                    )}
                                    {selectedMicroareas.length !==0 ?
                                        <label className="landmark_label no-orient">
                                            <input checked={not_obj === 'not_obj'} value="not_obj" onChange={this.handleNotObj} id="not_obj" name="not_obj" type="checkbox"/>
                                            <b></b>
                                            <span >Без ориентира</span>
                                        </label> :
                                        <></>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                </div>
                <div className="modal-footer">
                    <button 
                    onClick={this.handleClose} 
                    className="close_modal">
                        Отмена
                    </button>
                    <button 
                    onClick={this.handleSubmit}
                    className="submit_modal">
                        Применить
                    </button>
                </div>

                </Modal>

            </>
            
        );
    } 
  
}

export default SubAddress;